import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../Api/axios.instance";
import { LoadingButton } from "@mui/lab";
import useSession from "../../CustomHooks/useSession";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

export default function ActiveStudentDetails() {
  const [results, setResults] = useState([]);
  const [clusters, setClusters] = React.useState([]);
  const { currentPage, accesLevel, access } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const [anchorEl, setAnchorEl] = useState(null);
  const [formData, setFormData] = useState({
    StatusCode: "",
    dateFrom: dayjs().format("YYYY-MM-DD"),
  });
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (accesLevel === "ADMIN") {
      (async () => {
        try {
          const { data, status } = await axiosInstance.get("/clusters/all");
          if (status === 500) {
            setClusters(data.data);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [null]);

  useEffect(() => {
    console.log(results);
  }, [results]);

  const _onSubmit = async () => {
    const searchParams = new URLSearchParams();
    if (accesLevel === "ADMIN" || accesLevel === "SCHOOL") {
      const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
        .SchoolId;
      searchParams.append("schoolid", schoolID);
    }
    try {
      if (accesLevel === "ADMIN" || accesLevel === "SCHOOL") {
        const { data, status } = await axiosInstance.get(
          "/reports/active-student-report-school-wise",
          {
            params: searchParams,
          }
        );
        let filteredResults = data.data[0].filter(
          (item) => item.StatusCode === formData.StatusCode
        );

        console.log(formData.dateFrom, formData.StatusCode);
        // if (formData.StatusCode === "DROPOUT") {
        //   filteredResults = filteredResults.filter((x) => {
        //     return x.Dropout_Date.split("T")[0] === formData.dateFrom;
        //   });
        // }
        console.log("activeStudentDetails", filteredResults);
        setResults(filteredResults);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const exportPDF = () => {
    const doc = new jsPDF({ orientation: "landscape" });
    const cardWidth = 280;
    const cardHeight = 200;
    const startY = 10;
    const spaceBetweenCards = 10;
    const rowsPerPage = 1;
    const pageCount = Math.ceil(results.length / rowsPerPage);

    for (let page = 0; page < pageCount; page++) {
      if (page > 0) {
        doc.addPage();
      }

      const startIndex = page * rowsPerPage;
      const endIndex = Math.min(startIndex + rowsPerPage, results.length);
      const rowsToShow = results.slice(startIndex, endIndex);

      rowsToShow.forEach((item, index) => {
        const cardY = startY + index * (cardHeight + spaceBetweenCards);
        doc.setDrawColor(0);
        doc.setFillColor(255);
        doc.rect(10, cardY, cardWidth, cardHeight, "F");
        const startX = 15;
        let textY = cardY + 10;

        Object.entries(item).forEach(([key, value]) => {
          if (textY < cardY + cardHeight - 5) {
            doc.setFontSize(10);
            doc.text(startX, textY, `${key}: ${value}`);
            textY += 5;
          } else {
            doc.addPage();
            textY = startY + 10;
          }
        });
      });
    }

    doc.save("Active-Students-Details.pdf");
  };

  const exportXLSX = () => {
    const worksheet = XLSX.utils.json_to_sheet(results, {
      header: [],
    });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      "Active Students Details"
    );
    XLSX.writeFile(workbook, "Active-Students-Details.xlsx");
  };

  const cellStyle = {
    fontSize: "12px",
    border: "0px",
  };

  const _handleOnChange = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const _handleDateFrom = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        dateFrom: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };

  return (
    <Box flexGrow={1}>
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="h6" component="h1">
            Active Student's Details
          </Typography>
          <Typography variant="subtitle2" component="span">
            Select Status Code to see Active Students Details
          </Typography>
        </Stack>
      </Stack>
      {/* PDF XLSX */}
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box textAlign="right">
            <Button
              variant="outlined"
              color="primary"
              sx={{ mr: 1 }}
              onClick={exportPDF}
            >
              <PictureAsPdfIcon color="primary" />
            </Button>
            <Button onClick={exportXLSX} variant="outlined" color="primary">
              <FileDownloadIcon color="primary" />
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            id="standard-select-currency"
            select
            label="Status of Student"
            sx={{ display: "flex", flex: 1 }}
            variant="outlined"
            size="small"
            name="StatusCode"
            value={formData.StatusCode}
            onChange={_handleOnChange}
          >
            <MenuItem value="ACTIVE">ACTIVE</MenuItem>
            <MenuItem value="INACTIVE">INACTIVE</MenuItem>
            <MenuItem value="PASSOUT">PASSOUT</MenuItem>
            <MenuItem value="DROPOUT">DROPOUT</MenuItem>
          </TextField>
        </Grid>
        {/* <Grid item xs={12} md={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              inputFormat="DD-MM-YYYY"
              label="Select Date"
              name="from"
              value={formData.dateFrom}
              onChange={_handleDateFrom}
              renderInput={(params) => (
                <TextField
                  sx={{ display: "flex", flex: 1 }}
                  size="small"
                  required
                  {...params}
                  disabled={formData.StatusCode !== "DROPOUT"} // Disable if not DROPOUT
                />
              )}
            />
          </LocalizationProvider>
        </Grid> */}
        <Grid item xs={12} md={6}>
          <LoadingButton
            type="submit"
            onClick={_onSubmit}
            fullWidth
            variant="contained"
            color="primary"
          >
            Get Student Detail's
          </LoadingButton>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />

      <Grid container spacing={2}>
        {results.map((item, index) => (
          <Grid item xs={12} key={index}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {index + 1}. {item.StudentName}
                </Typography>
                <Divider />
                <Grid container spacing={2} style={{ marginTop: "8px" }}>
                  <Grid item xs={4}>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>ActiveSession:</strong> {item.ActiveSession}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Address:</strong> {item.Address}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Average_Monthly_Income:</strong>{" "}
                      {item.Average_Monthly_Income}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>ClassID:</strong> {item.ClassID}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>ClassName:</strong> {item.ClassName}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>CurrentSession:</strong> {item.CurrentSession}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>DOB:</strong> {item.DOB}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Date_Of_Addmission:</strong>{" "}
                      {item.Date_Of_Addmission}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Dependents:</strong> {item.Dependents}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>District:</strong> {item.District}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Father_Occupation:</strong>{" "}
                      {item.Father_Occupation}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Father_Qualification:</strong>{" "}
                      {item.Father_Qualification}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>FathersName:</strong> {item.FathersName}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Fee:</strong> {item.Fee}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>GRNO:</strong> {item.GRNO}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Gender:</strong> {item.Gender}
                    </Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>GuardianName:</strong> {item.GuardianName}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>GuardianRelation:</strong> {item.GuardianRelation}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>IsFatherNazra_Done:</strong>{" "}
                      {item.IsFatherNazra_Done}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>IsMotherNazra_Done:</strong>{" "}
                      {item.IsMotherNazra_Done}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>IsNazra_Done:</strong> {item.IsNazra_Done}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>IsOrphan:</strong> {item.IsOrphan}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>MotherTongue:</strong> {item.MotherTongue}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Mother_Occupation:</strong>{" "}
                      {item.Mother_Occupation}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Mother_qualification:</strong>{" "}
                      {item.Mother_qualification}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>NIC_Father:</strong> {item.NIC_Father}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>NIC_Guardian:</strong> {item.NIC_Guardian}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>NIC_Mother:</strong> {item.NIC_Mother}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>NameOfSchool:</strong> {item.NameOfSchool}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Ntionality:</strong> {item.Ntionality}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Old_ClassName:</strong> {item.Old_ClassName}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Old_School_Name:</strong> {item.Old_School_Name}
                    </Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Parent_Cell_No:</strong> {item.Parent_Cell_No}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Parent_Education_Highest_Level:</strong>{" "}
                      {item.Parent_Education_Highest_Level}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Parent_Whatssapp_No:</strong>{" "}
                      {item.Parent_Whatssapp_No}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>PermanentAddress:</strong> {item.PermanentAddress}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Present_Address:</strong> {item.Present_Address}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>RegionName:</strong> {item.RegionName}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Religion:</strong> {item.Religion}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Remarks:</strong> {item.Remarks}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>SID:</strong> {item.SID}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>School_code:</strong> {item.School_code}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Siblings:</strong> {item.Siblings}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>StatusCode:</strong> {item.StatusCode}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>Taluka:</strong> {item.Taluka}
                    </Typography>
                    <Typography variant="body1" style={cellStyle}>
                      <strong>UC:</strong> {item.UC}
                    </Typography>
                    {(formData.StatusCode === "DROPOUT") ?
                    (
                      <Typography variant="body1" style={cellStyle}>
                        <strong>Dropout_Date:</strong>{" "}
                        {dayjs(item.Dropout_Date).format("DD-MM-YYYY")}
                      </Typography>
                    ): ("")}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
