import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useSession from "../../CustomHooks/useSession";
import MuiAlert from "@mui/material/Alert";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { getClasses } from "../ClassSubjects/Classes";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

export default function StudentsByGradeSchoolClassWise() {
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [results, setResults] = useState([]);
  const [schoolList, setSchoolList] = React.useState([]);
  const [clusters, setClusters] = React.useState([]);
  const { currentPage, accesLevel, access } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const [formData, setFormData] = useState({
    test_type: "",
    session: "",
    SchoolId: "",
  });

  useEffect(() => {
    _fetchClasses();
  }, []);

  useEffect(() => {
    console.log("header component");
    if (accesLevel === "ADMIN") {
      (async () => {
        try {
          const { data, status } = await axiosInstance.get("/clusters/all");
          if (status === 200) {
            setClusters(data.data);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }
    if (accesLevel === "SCHOOL") {
      (async () => {
        try {
          const { data, status } = await axiosInstance.get("/clusters/all");
          if (status === 200) {
            setClusters(data.data);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }

    if (accesLevel === "CLUSTER") {
      (async () => {
        try {
          const { data, status } = await axiosInstance.get("/clusters/all");
          if (status === 200) {
            setClusters(data.data);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, []);

  const _fetchClasses = async () => {
    try {
      const { data } = await axiosInstance.get("/classes");
      setClasses(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const _onSubmit = async (e) => {
    e.preventDefault();
    console.log("formData ===>", formData);
    const searchParams = new URLSearchParams();
    searchParams.append("session", formData.session);
    searchParams.append("test_type", formData.test_type);
    searchParams.append("cluster_id", formData.RegionId);

    try {
      if (accesLevel === "ADMIN") {
        const { data, status } = await axiosInstance.get(
          "/reports/student-by-grade-and-school-class-wise",
          {
            params: searchParams,
          }
        );
        const outputObject = {};

        console.log("data.data[0]", data.data[0]);
        const nonZeroResult = data.data[0].map((result) => {
          return {
            ClassName: result.Nameofschool,
            Percentage: result.percentage,
            Male: result.Male == null ? 0 : result.Male,
            Female: result.Female == null ? 0 : result.Female,
          };
        });

        console.log("nonZeroResult", nonZeroResult);
        nonZeroResult.forEach((item) => {
          const { ClassName, Percentage, Male, Female } = item;
          if (!outputObject[ClassName]) {
            outputObject[ClassName] = {};
          }
          outputObject[ClassName][
            `Grade${Percentage}Male`.split(" ").join("")
          ] = parseInt(Male);
          outputObject[ClassName][
            `Grade${Percentage}Female`.split(" ").join("")
          ] = parseInt(Female);
          outputObject[ClassName][
            `Total${Percentage}Students`.split(" ").join("")
          ] = parseInt(Male) + parseInt(Female);
        });

        const outputArray = [];
        for (const classname in outputObject) {
          const data = outputObject[classname];
          const classData = { ClassName: classname, ...data };
          outputArray.push(classData);
        }
      console.log("outputArray", outputArray);
        if (status === 200) {
          setResults(outputArray);
        }
      }

      if (accesLevel === "SCHOOL") {
        const { data, status } = await axiosInstance.get(
          "/reports/student-by-grade-and-school-class-wise",
          {
            params: searchParams,
          }
        );
        const outputObject = {};

        console.log("data.data[0]", data.data[0]);
        const nonZeroResult = data.data[0].map((result) => {
          return {
            ClassName: result.Nameofschool,
            Percentage: result.percentage,
            Male: result.Male == null ? 0 : result.Male,
            Female: result.Female == null ? 0 : result.Female,
          };
        });

        console.log("nonZeroResult", nonZeroResult);
        nonZeroResult.forEach((item) => {
          const { ClassName, Percentage, Male, Female } = item;
          if (!outputObject[ClassName]) {
            outputObject[ClassName] = {};
          }
          outputObject[ClassName][
            `Grade${Percentage}Male`.split(" ").join("")
          ] = parseInt(Male);
          outputObject[ClassName][
            `Grade${Percentage}Female`.split(" ").join("")
          ] = parseInt(Female);
          outputObject[ClassName][
            `Total${Percentage}Students`.split(" ").join("")
          ] = parseInt(Male) + parseInt(Female);
        });

        const outputArray = [];
        for (const classname in outputObject) {
          const data = outputObject[classname];
          const classData = { ClassName: classname, ...data };
          outputArray.push(classData);
        }
        console.log("outputArray", outputArray);
        if (status === 200) {
          setResults(outputArray);
        }
      }

      if (accesLevel === "CLUSTER") {
        const { data, status } = await axiosInstance.get(
          "/reports/student-by-grade-and-school-class-wise",
          {
            params: searchParams,
          }
        );
        const outputObject = {};

        console.log("data.data[0]", data.data[0]);
        const nonZeroResult = data.data[0].map((result) => {
          return {
            ClassName: result.Nameofschool,
            Percentage: result.percentage,
            Male: result.Male == null ? 0 : result.Male,
            Female: result.Female == null ? 0 : result.Female,
          };
        });

        console.log("nonZeroResult", nonZeroResult);
        nonZeroResult.forEach((item) => {
          const { ClassName, Percentage, Male, Female } = item;
          if (!outputObject[ClassName]) {
            outputObject[ClassName] = {};
          }
          outputObject[ClassName][
            `Grade${Percentage}Male`.split(" ").join("")
          ] = parseInt(Male);
          outputObject[ClassName][
            `Grade${Percentage}Female`.split(" ").join("")
          ] = parseInt(Female);
          outputObject[ClassName][
            `Total${Percentage}Students`.split(" ").join("")
          ] = parseInt(Male) + parseInt(Female);
        });

        const outputArray = [];
        for (const classname in outputObject) {
          const data = outputObject[classname];
          const classData = { ClassName: classname, ...data };
          outputArray.push(classData);
        }
        console.log("outputArray", outputArray);
        if (status === 200) {
          setResults(outputArray);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _handleClassChange = async (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
    try {
      const { data, status } = await axiosInstance.get(
        `/class-subjects/by-class/${e.target.value}`
      );
      console.log("subjects", data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const _handleOnChange = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const _handleDateFrom = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        from: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };

  const Return0IfNull = (value) => {
    if (value === undefined) {
      return 0;
    }
    return value;
  }

  const Total = (TotalFAILStudents ,
    TotalFStudents ,
    TotalEStudents ,
    TotalDStudents ,
    TotalCStudents ,
    TotalBStudents ,
    TotalAStudents ,
    TotalA1Students) => {
      return (Return0IfNull(TotalFAILStudents) + Return0IfNull(TotalFStudents) + Return0IfNull(TotalEStudents) + Return0IfNull(TotalDStudents) + Return0IfNull(TotalCStudents) + Return0IfNull(TotalBStudents) + Return0IfNull(TotalAStudents) + Return0IfNull(TotalA1Students))
  }

  const _handleOnAdminClusterChange = (e) => {
    const cluster = clusters.filter((item) => item.RegionId === e.target.value);
    console.log("cluster", cluster);
    console.log("schools", cluster[0].School);
    setSchoolList(cluster[0].School);
    _handleOnChange(e);
  };

  const _handleDateTo = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        to: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };

  function calculateGrade(percentage) {
    if (percentage >= 90) {
      return "A+";
    } else if (percentage >= 80) {
      return "A";
    } else if (percentage >= 70) {
      return "B";
    } else if (percentage >= 60) {
      return "C";
    } else if (percentage >= 50) {
      return "D";
    } else {
      return "F";
    }
  }

  const exportPDF = () => {
    const doc = new jsPDF({ orientation: "landscape" });

    doc.autoTable({
      html: "#my-table",
    });

    doc.save("school-wise-grade-summary.pdf");
  };

  const exportXLSX = () => {
    handleExport().then((url) => {
      console.log(url);
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        "school-wise-grade-summary.xlsx"
      );
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const workbook2Blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      type: "binary",
    };
    const wbOut = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wbOut)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const handleExport = () => {
    let baithakLogo = [{}];

    let title = [{ A: "Exam Details Cluster/School Wise Grade Summary" }, {}];

    let table = [
      {
        A: "S.No",
        B: "Cluster Name",
        C: "",
        D: "",
        E: "",
        F: "",
        G: "",
        H: "",
        I: "",
        J: "",
        K: "",
        L: "",
        M: "",
        N: "",
        O: "",
        P: "",
        Q: "",
        R: "",
        S: "",
        T: "",
        U: "",
        V: "",
        W: "",
        X: "",
        Y: "",
        Z: "",
        AA: "",
      },
    ];

    table.push({
      A: "",
      B: "",
      C: "",
      D: "",
      E: "",
      F: "",
      G: "",
      H: "",
      I: "",
      J: "",
      K: "",
      L: "",
      M: "",
      N: "",
      O: "",
      P: "",
      Q: "",
      R: "",
      S: "",
      T: "",
      U: "",
      V: "",
      W: "",
      X: "",
      Y: "",
      Z: ""
    });

    results.length > 0 &&
      results.forEach((result, index) => {
        table.push({
          A: index + 1,
          B: result.ClassName,
          C: result.GradeA1Male,
          D: result.GradeA1Female,
          E: result.TotalA1Students,
          F: result.GradeAMale,
          G: result.GradeAFemale,
          H: result.TotalAStudents,
          I: result.GradeBMale,
          J: result.GradeBFemale,
          K: result.TotalBStudents,
          L: result.GradeCMale,
          M: result.GradeCFemale,
          N: result.TotalCStudents,
          O: result.GradeDMale,
          P: result.GradeDFemale,
          Q: result.TotalDStudents,
          R: result.GradeEMale,
          S: result.GradeEFemale,
          T: result.TotalEStudents,
          U: result.GradeFMale,
          V: result.GradeFFemale,
          W: result.TotalFStudents,
          X: result.GradeFAILMale,
          Y: result.GradeFAILFemale,
          Z: result.TotalFAILStudents,
          AA: Total(result.TotalA1Students , result.TotalAStudents , result.TotalBStudents , result.TotalCStudents , result.TotalDStudents , result.TotalEStudents , result.TotalFStudents , result.TotalFAILStudents)
        });
      });

    table = [{ }].concat(table).concat([""]);

    const finalData = [...title, ...table];
    const wb = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    const merges = [
      { s: { r: 3, c: 2 }, e: { r: 3, c: 4 } },
      { s: { r: 3, c: 5 }, e: { r: 3, c: 7 } },
      { s: { r: 3, c: 8 }, e: { r: 3, c: 10 } },
      { s: { r: 3, c: 11 }, e: { r: 3, c: 13 } }, 
      { s: { r: 3, c: 14 }, e: { r: 3, c: 16 } }, 
      { s: { r: 3, c: 17 }, e: { r: 3, c: 19 } }, 
      { s: { r: 3, c: 20 }, e: { r: 3, c: 22 } }, 
      { s: { r: 3, c: 23 }, e: { r: 3, c: 25 } }, 
      { s: { r: 3, c: 26 }, e: { r: 3, c: 26 } }, 
    ];

    sheet["!merges"] = merges;
    sheet["C4"] = {
      v: "A1",
      s: { alignment: { horizontal: "center", vertical: "center" } },
    };
    sheet["F4"] = {
      v: "A",
      s: { alignment: { horizontal: "center", vertical: "center" } },
    };
    sheet["I4"] = {
      v: "B",
      s: { alignment: { horizontal: "center", vertical: "center" } },
    };
    sheet["L4"] = {
      v: "C",
      s: { alignment: { horizontal: "center", vertical: "center" } },
    };
    sheet["O4"] = {
      v: "D",
      s: { alignment: { horizontal: "center", vertical: "center" } },
    };
    sheet["R4"] = {
      v: "E",
      s: { alignment: { horizontal: "center", vertical: "center" } },
    };
    sheet["U4"] = {
      v: "F",
      s: { alignment: { horizontal: "center", vertical: "center" } },
    };
    sheet["X4"] = {
      v: "FAIL",
      s: { alignment: { horizontal: "center", vertical: "center" } },
    };
    sheet["AA4"] = {
      v: "Total",
      s: { alignment: { horizontal: "center", vertical: "center" } },
    };
    sheet["C5"] = {
      v: "Male",
    };
    sheet["D5"] = {
      v: "Female",
    };
    sheet["E5"] = {
      v: "Total",
    };
    sheet["F5"] = {
      v: "Male",
    };
    sheet["G5"] = {
      v: "Female",
    };
    sheet["H5"] = {
      v: "Total",
    };
    sheet["I5"] = {
      v: "Male",
    };
    sheet["J5"] = {
      v: "Female",
    };
    sheet["K5"] = {
      v: "Total",
    };
    sheet["L5"] = {
      v: "Male",
    };
    sheet["M5"] = {
      v: "Female",
    };
    sheet["N5"] = {
      v: "Total",
    };
    sheet["O5"] = {
      v: "Male",
    };
    sheet["P5"] = {
      v: "Female",
    };
    sheet["Q5"] = {
      v: "Total",
    };
    sheet["R5"] = {
      v: "Male",
    };
    sheet["S5"] = {
      v: "Female",
    };
    sheet["T5"] = {
      v: "Total",
    };
    sheet["U5"] = {
      v: "Male",
    };
    sheet["V5"] = {
      v: "Female",
    };
    sheet["W5"] = {
      v: "Total",
    };
    sheet["X5"] = {
      v: "Male",
    };
    sheet["Y5"] = {
      v: "Female",
    };
    sheet["Z5"] = {
      v: "Total",
    };

    XLSX.utils.book_append_sheet(wb, sheet, "report-student-strength");

    const workbookBlob = workbook2Blob(wb);

    const headerIndexes = [];
    finalData.forEach((data, index) =>
      data["A"] === "S.No" ? headerIndexes.push(index) : null
    );

    const dataInfo = {
      titleCell: "A2",
      titleRange: "A1:G2",
      tbodyRange: `A3:AA${finalData.length}`,
      theadRange:
        headerIndexes.length >= 1
          ? `A${headerIndexes[0] + 1}:AA${headerIndexes[0] + 1}`
          : null,
    };
    return addStyles(workbookBlob, dataInfo);
  };

  const addStyles = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        // sheet.usedRange.style({
        //   fontFamily: "Arial",
        //   verticalAlignment: "center",
        // });

        sheet.column("A").width(15);
        sheet.column("B").width(20);
        sheet.column("C").width(10);
        sheet.column("D").width(10);
        sheet.column("E").width(10);
        sheet.column("F").width(10);
        sheet.column("G").width(10);

        sheet.range(dataInfo.titleRange).merged(true).style({
          bold: true,
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });

        sheet.range(dataInfo.titleRange).style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });

        sheet.range(dataInfo.tbodyRange).style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });

        sheet.range(dataInfo.theadRange).style({
          fill: "808080",
          bold: true,
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  return (
    <Box flexGrow={1}>
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="h6" component="h1">
            Exam Details Cluster/School Wise Grade Summary
          </Typography>
        </Stack>
       
      </Stack>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box textAlign="right">
            <Button
              variant="outlined"
              color="primary"
              sx={{ mr: 1 }}
              onClick={exportPDF}
            >
              <PictureAsPdfIcon color="primary" />
            </Button>
            <Button onClick={exportXLSX} variant="outlined" color="primary">
              <FileDownloadIcon color="primary" />
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            id="standard-select-currency"
            select
            label="Select Cluster"
            sx={{ display: "flex", flex: 1 }}
            variant="outlined"
            name="RegionId"
            required
            onChange={_handleOnAdminClusterChange}
            size="small"
          >
            {clusters.map((cluster, index) => {
              return (
                <MenuItem key={index} value={cluster.RegionId}>
                  {cluster.RegionName}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="standard-select-currency"
            select
            label="Select Session"
            sx={{ display: "flex", flex: 1 }}
            variant="outlined"
            name="session"
            onChange={_handleOnChange}
            size="small"
          >
            <MenuItem value="2015-2016">2015-2016</MenuItem>
            <MenuItem value="2016-2017">2016-2017</MenuItem>
            <MenuItem value="2017-2018">2017-2018</MenuItem>
            <MenuItem value="2018-2019">2018-2019</MenuItem>
            <MenuItem value="2019-2020">2019-2020</MenuItem>
            <MenuItem value="2020-2021">2020-2021</MenuItem>
            <MenuItem value="2021-2022">2021-2022</MenuItem>
            <MenuItem value="2022-2023">2022-2023</MenuItem>
            <MenuItem value="2023-2024">2023-2024</MenuItem>
            <MenuItem value="2024-2025">2024-2025</MenuItem>
            <MenuItem value="2025-2026">2025-2026</MenuItem>
            <MenuItem value="2026-2027">2026-2027</MenuItem>
            <MenuItem value="2027-2028">2027-2028</MenuItem>
            <MenuItem value="2028-2029">2028-2029</MenuItem>
            <MenuItem value="2029-2030">2029-2030</MenuItem>
            <MenuItem value="2030-2031">2030-2031</MenuItem>
            <MenuItem value="2031-2032">2031-2032</MenuItem>
            <MenuItem value="2032-2033">2032-2033</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="standard-select-currency"
            select
            label="Select Test Type"
            sx={{ display: "flex", flex: 1 }}
            variant="outlined"
            name="test_type"
            onChange={_handleOnChange}
            size="small"
          >
            <MenuItem value="WEEKLY">WEEKLY</MenuItem>
            <MenuItem value="MONTHLY">MONTHLY</MenuItem>
            <MenuItem value="MID-TERM">MID-TERM</MenuItem>
            <MenuItem value="ANNUAL">ANNUAL</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <LoadingButton
            type="submit"
            onClick={_onSubmit}
            fullWidth
            variant="contained"
            color="primary"
          >
            Get Student Results
          </LoadingButton>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <div style={{ width: "100%" }}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              id="my-table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">S.No</TableCell>
                  <TableCell align="center">Cluster</TableCell>
                  <TableCell align="center">
                    <Box>
                      <Grid container>
                        <Grid xs={12}>A1</Grid>
                      </Grid>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Box>
                      <Grid container>
                        <Grid xs={12}>A</Grid>
                      </Grid>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Box>
                      <Grid container>
                        <Grid xs={12}>B</Grid>
                      </Grid>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Box>
                      <Grid container>
                        <Grid xs={12}>C</Grid>
                      </Grid>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Box>
                      <Grid container>
                        <Grid xs={12}>D</Grid>
                      </Grid>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Box>
                      <Grid container>
                        <Grid xs={12}>E</Grid>
                      </Grid>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Box>
                      <Grid container>
                        <Grid xs={12}>F</Grid>
                      </Grid>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Box>
                      <Grid container>
                        <Grid xs={12}>FAIL</Grid>
                      </Grid>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Box>
                      <Grid container>
                        <Grid xs={12}>Total</Grid>
                      </Grid>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">
                    <Box>
                      <Grid container sx={{ fontSize: 12, flexWrap: "nowrap" }}>
                        <Grid xs={4}>Male</Grid>
                        <Grid
                          xs={4}
                          sx={{ marginLeft: "5px", marginRight: "5px" }}
                        >
                          Female
                        </Grid>
                        <Grid xs={4}>Total</Grid>
                      </Grid>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Box>
                      <Grid container sx={{ fontSize: 12, flexWrap: "nowrap" }}>
                        <Grid xs={4}>Male</Grid>
                        <Grid
                          xs={4}
                          sx={{ marginLeft: "5px", marginRight: "5px" }}
                        >
                          Female
                        </Grid>
                        <Grid xs={4}>Total</Grid>
                      </Grid>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Box>
                      <Grid container sx={{ fontSize: 12, flexWrap: "nowrap" }}>
                        <Grid xs={4}>Male</Grid>
                        <Grid
                          xs={4}
                          sx={{ marginLeft: "5px", marginRight: "5px" }}
                        >
                          Female
                        </Grid>
                        <Grid xs={4}>Total</Grid>
                      </Grid>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Box>
                      <Grid container sx={{ fontSize: 12, flexWrap: "nowrap" }}>
                        <Grid xs={4}>Male</Grid>
                        <Grid
                          xs={4}
                          sx={{ marginLeft: "5px", marginRight: "5px" }}
                        >
                          Female
                        </Grid>
                        <Grid xs={4}>Total</Grid>
                      </Grid>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Box>
                      <Grid container sx={{ fontSize: 12, flexWrap: "nowrap" }}>
                        <Grid xs={4}>Male</Grid>
                        <Grid
                          xs={4}
                          sx={{ marginLeft: "5px", marginRight: "5px" }}
                        >
                          Female
                        </Grid>
                        <Grid xs={4}>Total</Grid>
                      </Grid>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Box>
                      <Grid container sx={{ fontSize: 12, flexWrap: "nowrap" }}>
                        <Grid xs={4}>Male</Grid>
                        <Grid
                          xs={4}
                          sx={{ marginLeft: "5px", marginRight: "5px" }}
                        >
                          Female
                        </Grid>
                        <Grid xs={4}>Total</Grid>
                      </Grid>
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Box>
                      <Grid container sx={{ fontSize: 12, flexWrap: "nowrap" }}>
                        <Grid xs={4}>Male</Grid>
                        <Grid
                          xs={4}
                          sx={{ marginLeft: "5px", marginRight: "5px" }}
                        >
                          Female
                        </Grid>
                        <Grid xs={4}>Total</Grid>
                      </Grid>
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Box>
                      <Grid container sx={{ fontSize: 12, flexWrap: "nowrap" }}>
                        <Grid xs={4}>Male</Grid>
                        <Grid
                          xs={4}
                          sx={{ marginLeft: "5px", marginRight: "5px" }}
                        >
                          Female
                        </Grid>
                        <Grid xs={4}>Total</Grid>
                      </Grid>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.length > 0 &&
                  results.map((item, index) => (
                    <TableRow
                      key={String(index)}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{item.ClassName}</TableCell>
                      <TableCell>
                        <Box>
                          <Grid
                            container
                            sx={{ fontSize: 12, flexWrap: "nowrap" }}
                          >
                            <Grid xs={4}>
                              {item.GradeA1Male === NaN ? 0 : item.GradeA1Male}
                            </Grid>
                            <Grid
                              xs={4}
                              sx={{
                                marginLeft: "5px",
                                marginRight: "5px",
                              }}
                            >
                              {item.GradeA1Female === NaN
                                ? 0
                                : item.GradeA1Female}
                            </Grid>
                            <Grid xs={4}>{item.TotalA1Students}</Grid>
                          </Grid>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Grid
                            container
                            sx={{ fontSize: 12, flexWrap: "nowrap" }}
                          >
                            <Grid xs={4}>
                              {item.GradeAMale === NaN ? 0 : item.GradeAMale}
                            </Grid>
                            <Grid
                              xs={4}
                              sx={{
                                marginLeft: "5px",
                                marginRight: "5px",
                              }}
                            >
                              {item.GradeAFemale === NaN
                                ? 0
                                : item.GradeAFemale}
                            </Grid>
                            <Grid xs={4}>{item.TotalAStudents}</Grid>
                          </Grid>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Grid
                            container
                            sx={{ fontSize: 12, flexWrap: "nowrap" }}
                          >
                            <Grid xs={4}>
                              {item.GradeBMale === NaN ? 0 : item.GradeBMale}
                            </Grid>
                            <Grid
                              xs={4}
                              sx={{
                                marginLeft: "5px",
                                marginRight: "5px",
                              }}
                            >
                              {item.GradeBFemale === NaN
                                ? 0
                                : item.GradeBFemale}
                            </Grid>
                            <Grid xs={4}>{item.TotalBStudents}</Grid>
                          </Grid>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Grid
                            container
                            sx={{ fontSize: 12, flexWrap: "nowrap" }}
                          >
                            <Grid xs={4}>
                              {item.GradeCMale === NaN ? 0 : item.GradeCMale}
                            </Grid>
                            <Grid
                              xs={4}
                              sx={{
                                marginLeft: "5px",
                                marginRight: "5px",
                              }}
                            >
                              {item.GradeCFemale === NaN
                                ? 0
                                : item.GradeCFemale}
                            </Grid>
                            <Grid xs={4}>{item.TotalCStudents}</Grid>
                          </Grid>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Grid
                            container
                            sx={{ fontSize: 12, flexWrap: "nowrap" }}
                          >
                            <Grid xs={4}>
                              {item.GradeDMale === NaN ? 0 : item.GradeDMale}
                            </Grid>
                            <Grid
                              xs={4}
                              sx={{
                                marginLeft: "5px",
                                marginRight: "5px",
                              }}
                            >
                              {item.GradeDFemale === NaN
                                ? 0
                                : item.GradeDFemale}
                            </Grid>
                            <Grid xs={4}>{item.TotalDStudents}</Grid>
                          </Grid>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Grid
                            container
                            sx={{ fontSize: 12, flexWrap: "nowrap" }}
                          >
                            <Grid xs={4}>
                              {item.GradeEMale === NaN ? 0 : item.GradeEMale}
                            </Grid>
                            <Grid
                              xs={4}
                              sx={{
                                marginLeft: "5px",
                                marginRight: "5px",
                              }}
                            >
                              {item.GradeEFemale === NaN
                                ? 0
                                : item.GradeEFemale}
                            </Grid>
                            <Grid xs={4}>{item.TotalEStudents}</Grid>
                          </Grid>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Grid
                            container
                            sx={{ fontSize: 12, flexWrap: "nowrap" }}
                          >
                            <Grid xs={4}>
                              {item.GradeFMale === NaN ? 0 : item.GradeFMale}
                            </Grid>
                            <Grid
                              xs={4}
                              sx={{
                                marginLeft: "5px",
                                marginRight: "5px",
                              }}
                            >
                              {item.GradeFFemale === NaN
                                ? 0
                                : item.GradeFFemale}
                            </Grid>
                            <Grid xs={4}>{item.TotalFStudents}</Grid>
                          </Grid>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Grid
                            container
                            sx={{ fontSize: 12, flexWrap: "nowrap" }}
                          >
                            <Grid xs={4}>
                              {item.GradeFAILMale === NaN
                                ? 0
                                : item.GradeFAILMale}
                            </Grid>
                            <Grid
                              xs={4}
                              sx={{
                                marginLeft: "5px",
                                marginRight: "5px",
                              }}
                            >
                              {item.GradeFAILFemale === NaN
                                ? 0
                                : item.GradeFAILFemale}
                            </Grid>
                            <Grid xs={4}>{item.TotalFAILStudents}</Grid>
                          </Grid>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Grid
                            container
                            sx={{ fontSize: 12, flexWrap: "nowrap" }}
                          >
                            <Grid xs={4}>{Total(item.TotalFAILStudents ,
                                item.TotalFStudents ,
                                item.TotalEStudents ,
                                item.TotalDStudents ,
                                item.TotalCStudents ,
                                item.TotalBStudents ,
                                item.TotalAStudents ,
                                item.TotalA1Students)}</Grid>
                          </Grid>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Stack>
    </Box>
  );
}
