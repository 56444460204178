import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useSession from "../../CustomHooks/useSession";
import MuiAlert from "@mui/material/Alert";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { getClasses } from "../ClassSubjects/Classes";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

export default function StudentsDropoutsSessionWise() {
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [results, setResults] = useState([]);
  const [schoolList, setSchoolList] = React.useState([]);
  const [clusters, setClusters] = React.useState([]);
  const { currentPage, accesLevel, access } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const [formData, setFormData] = useState({
    session: "",
    dateFrom: dayjs().format("YYYY-MM-DD"),
    dateTo: dayjs().format("YYYY-MM-DD"),
  });
  const classesHead = [
    "KG I",
    "KG II",
    "I",
    "II",
    "III",
    "IV",
    "V",
    "VI",
    "VII",
    "VIII",
    "IX",
  ];

  useEffect(() => {
    _fetchClasses();
  }, []);

  useEffect(() => {
    console.log("header component");
    if (accesLevel === "ADMIN") {
      (async () => {
        try {
          const { data, status } = await axiosInstance.get("/clusters/all");
          if (status === 200) {
            setClusters(data.data);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }
    if (accesLevel === "CLUSTER") {
      (async () => {
        try {
          const { data, status } = await axiosInstance.get("/clusters/all");
          if (status === 200) {
            setClusters(data.data);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, []);

  const _fetchClasses = async () => {
    try {
      const { data } = await axiosInstance.get("/classes");
      setClasses(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const _onSubmit = async (e) => {
    e.preventDefault();
    console.log("formData ===>", formData);
    const searchParams = new URLSearchParams();
    searchParams.append("session", formData.session);
    searchParams.append("dateFrom", formData.dateFrom);
    searchParams.append("dateTo", formData.dateTo);

    try {
      if (accesLevel === "ADMIN") {
        const { data, status } = await axiosInstance.get(
          "/reports/student-droupout-data-sessionwise",
          {
            params: searchParams,
          }
        );
        // const outputObject = {};
        console.log("data.data[0]", 121, data.data[0]);
        let obj = {};
        data.data[0].forEach((x) => {
          if (!(`${x.RegionName}/${x.NameOfSchool}` in obj)) {
            obj[`${x.RegionName}/${x.NameOfSchool}`] = [];
          }
          obj[`${x.RegionName}/${x.NameOfSchool}`].push({
            ClassName: x.ClassName,
            SchoolId: x.SchoolId,
            Male: x.Male,
            Female: x.Female,
            Total: x.Male + x.Female,
          });
        });
        Object.keys(obj).forEach((item) => {
          classesHead.forEach((c) => {
            if (!obj[item].some((x) => x.ClassName === c)) {
              obj[item].push({
                ClassName: c,
                SchoolId: 0,
                Male: 0,
                Female: 0,
                Total: 0,
              });
            }
          });
          obj[item].sort((a, b) => {
            return (
              classesHead.indexOf(a.ClassName) -
              classesHead.indexOf(b.ClassName)
            );
          });
        });
        console.log("sadsadsad=>", obj);
        setResults(obj);
      }
      if (accesLevel === "CLUSTER") {
        const { data, status } = await axiosInstance.get(
          "/reports/student-droupout-data-sessionwise",
          {
            params: searchParams,
          }
        );
        // const outputObject = {};
        console.log("data.data[0]", data.data[0]);
        let obj = {};
        data.data[0].forEach((x) => {
          if (!(`${x.RegionName}/${x.NameOfSchool}` in obj)) {
            obj[`${x.RegionName}/${x.NameOfSchool}`] = [];
          }
          obj[`${x.RegionName}/${x.NameOfSchool}`].push({
            ClassName: x.ClassName,
            SchoolId: x.SchoolId,
            Male: x.Male,
            Female: x.Female,
            Total: x.Male + x.Female,
          });
        });
        Object.keys(obj).forEach((item) => {
          classesHead.forEach((c) => {
            if (!obj[item].some((x) => x.ClassName === c)) {
              obj[item].push({
                ClassName: c,
                SchoolId: 0,
                Male: 0,
                Female: 0,
                Total: 0,
              });
            }
          });
          obj[item].sort((a, b) => {
            return (
              classesHead.indexOf(a.ClassName) -
              classesHead.indexOf(b.ClassName)
            );
          });
        });
        console.log("sadsadsad=>", obj);
        setResults(obj);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _handleOnChange = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const _handleDateFrom = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        dateFrom: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };

  const _handleOnAdminClusterChange = (e) => {
    const cluster = clusters.filter((item) => item.RegionId === e.target.value);
    console.log("cluster", cluster);
    console.log("schools", cluster[0].School);
    setSchoolList(cluster[0].School);
  };

  const _handleDateTo = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        dateTo: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };

  function calculateGrade(percentage) {
    if (percentage >= 90) {
      return "A+";
    } else if (percentage >= 80) {
      return "A";
    } else if (percentage >= 70) {
      return "B";
    } else if (percentage >= 60) {
      return "C";
    } else if (percentage >= 50) {
      return "D";
    } else {
      return "F";
    }
  }

  const exportPDF = () => {
    const doc = new jsPDF({ orientation: "landscape" });

    doc.autoTable({
      html: "#my-table",
    });

    doc.save("Baithak-Report.pdf");
  };

  const exportXLSX = () => {
    handleExport().then((url) => {
      console.log(url);
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        "student-strength-report.xlsx"
      );
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const workbook2Blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      type: "binary",
    };
    const wbOut = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wbOut)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const handleExport = () => {
    let baithakLogo = [{}];

    let title = [{ A: "Report Student Dropout Session Wise" }, {}];

    let table = [
      {
        A: "S.No",
        B: "Student Name",
        C: "Father Name",
        D: "GRNO",
        E: "Class in Admission",
        F: "Date of Admission",
        G: "Current Class",
      },
    ];

    students.length > 0 &&
      students.forEach((student, index) => {
        table.push({
          A: index + 1,
          B: student.StudentName,
          C: student.FathersName,
          D: student.GRNO,
          E: student.ClassModel ? student.ClassModel.ClassName : "",
          F: student.Date_Of_Addmission,
          G: student.ClassModel ? student.ClassModel.ClassName : "",
        });
      });

    table = [{ A: "Student Strength" }].concat(table).concat([""]);

    const finalData = [...title, ...table];

    // create workbook
    const wb = XLSX.utils.book_new();

    // create worksheet
    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, "report-student-strength");

    const workbookBlob = workbook2Blob(wb);

    const headerIndexes = [];
    finalData.forEach((data, index) =>
      data["A"] === "S.No" ? headerIndexes.push(index) : null
    );

    const dataInfo = {
      titleCell: "A2",
      titleRange: "A1:G2",
      tbodyRange: `A3:G${finalData.length}`,
      theadRange:
        headerIndexes.length >= 1
          ? `A${headerIndexes[0] + 1}:G${headerIndexes[0] + 1}`
          : null,
    };
    return addStyles(workbookBlob, dataInfo);
  };

  const addStyles = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        // sheet.usedRange.style({
        //   fontFamily: "Arial",
        //   verticalAlignment: "center",
        // });

        sheet.column("A").width(15);
        sheet.column("B").width(20);
        sheet.column("C").width(20);
        sheet.column("D").width(15);
        sheet.column("E").width(15);
        sheet.column("F").width(15);
        sheet.column("G").width(15);

        sheet.range(dataInfo.titleRange).merged(true).style({
          bold: true,
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });

        sheet.range(dataInfo.titleRange).style({
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });

        sheet.range(dataInfo.tbodyRange).style({
          horizontalAlignment: "left",
          verticalAlignment: "center",
        });

        sheet.range(dataInfo.theadRange).style({
          fill: "808080",
          bold: true,
          horizontalAlignment: "left",
          verticalAlignment: "center",
        });
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  return (
    <Box flexGrow={1}>
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="h6" component="h1">
            Dropout Students Session Wise
          </Typography>
          {/*<Typography variant="subtitle2" component="span">
                            Select Date to generate a new Daily Entries Report
                          </Typography>*/}
        </Stack>
        <Box width={300}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}></Grid>
          </Grid>
        </Box>
      </Stack>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box textAlign="right">
            <Button
              variant="outlined"
              color="primary"
              sx={{ mr: 1 }}
              onClick={exportPDF}
            >
              <PictureAsPdfIcon color="primary" />
            </Button>
            <Button onClick={exportXLSX} variant="outlined" color="primary">
              <FileDownloadIcon color="primary" />
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            id="standard-select-currency"
            select
            label="Select Session"
            sx={{ display: "flex", flex: 1 }}
            variant="outlined"
            name="session"
            onChange={_handleOnChange}
            size="small"
          >
            <MenuItem value="2015-2016">2015-2016</MenuItem>
            <MenuItem value="2016-2017">2016-2017</MenuItem>
            <MenuItem value="2017-2018">2017-2018</MenuItem>
            <MenuItem value="2018-2019">2018-2019</MenuItem>
            <MenuItem value="2019-2020">2019-2020</MenuItem>
            <MenuItem value="2020-2021">2020-2021</MenuItem>
            <MenuItem value="2021-2022">2021-2022</MenuItem>
            <MenuItem value="2022-2023">2022-2023</MenuItem>
            <MenuItem value="2023-2024">2023-2024</MenuItem>
            <MenuItem value="2024-2025">2024-2025</MenuItem>
            <MenuItem value="2025-2026">2025-2026</MenuItem>
            <MenuItem value="2026-2027">2026-2027</MenuItem>
            <MenuItem value="2027-2028">2027-2028</MenuItem>
            <MenuItem value="2028-2029">2028-2029</MenuItem>
            <MenuItem value="2029-2030">2029-2030</MenuItem>
            <MenuItem value="2030-2031">2030-2031</MenuItem>
            <MenuItem value="2031-2032">2031-2032</MenuItem>
            <MenuItem value="2032-2033">2032-2033</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              inputFormat="DD-MM-YYYY"
              label="Date From"
              value={formData.dateFrom}
              name="dateFrom"
              required
              onChange={_handleDateFrom}
              renderInput={(params) => (
                <TextField
                  sx={{ display: "flex", flex: 1, mr: 1 }}
                  size="small"
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              inputFormat="DD-MM-YYYY"
              label="Date To"
              value={formData.dateTo}
              name="dateTo"
              required
              onChange={_handleDateTo}
              renderInput={(params) => (
                <TextField
                  sx={{ display: "flex", flex: 1, mr: 1 }}
                  size="small"
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <LoadingButton
            //   loading={loading}
            //   disabled={loading}
            type="submit"
            onClick={_onSubmit}
            fullWidth
            variant="contained"
            color="primary"
          >
            Get Dropout Students
          </LoadingButton>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <div style={{ width: "100%" }}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              id="my-table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">S.No</TableCell>
                  <TableCell align="center">Cluster/School</TableCell>
                  {classesHead.map((c, i) => (
                    <TableCell key={i} align="center">
                      <Box>
                        <Grid container>
                          <Grid xs={12}>{c}</Grid>
                        </Grid>
                      </Box>
                    </TableCell>
                  ))}
                  <TableCell align="center">Total</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left"></TableCell>
                  <TableCell align="center"></TableCell>
                  {classesHead.map((c, i) => (
                    <TableCell key={i} align="center">
                      <Box>
                        <Grid
                          container
                          sx={{ fontSize: 12, flexWrap: "nowrap" }}
                        >
                          <Grid xs={4}>Male</Grid>
                          <Grid
                            xs={4}
                            sx={{ marginLeft: "5px", marginRight: "5px" }}
                          >
                            Female
                          </Grid>
                        </Grid>
                      </Box>
                    </TableCell>
                  ))}
                  <TableCell align="center">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(results).length > 0 &&
                  Object.keys(results).map((item, index) => {
                    let t = 0;
                    return (
                      <TableRow
                        key={String(index)}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{item}</TableCell>
                        {results[item].map((data, i) => {
                          t += data.Total;
                          return (
                            <TableCell key={String(i)}>
                              <Box>
                                <Grid
                                  container
                                  sx={{ fontSize: 12, flexWrap: "nowrap" }}
                                >
                                  <Grid xs={4}>
                                    {data.Male === null ? 0 : data.Male}
                                  </Grid>
                                  <Grid
                                    xs={4}
                                    sx={{
                                      marginLeft: "10px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    {data.Female === null ? 0 : data.Female}
                                  </Grid>
                                </Grid>
                              </Box>
                            </TableCell>
                          );
                        })}
                        <TableCell align="center">{t}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Stack>
    </Box>
  );
}