import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    Menu,
    MenuItem,
    Paper,
    Select,
    Snackbar,
    TextField,
    Typography,
  } from "@mui/material";
  import { Box, Stack } from "@mui/system";
  import React, { useContext, useEffect, useState } from "react";
  import { TextareaAutosize } from "@mui/base/TextareaAutosize";
  import Table from "@mui/material/Table";
  import TableBody from "@mui/material/TableBody";
  import TableCell from "@mui/material/TableCell";
  import TableContainer from "@mui/material/TableContainer";
  import TableHead from "@mui/material/TableHead";
  import TableRow from "@mui/material/TableRow";
  import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
  import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
  import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
  import { useLocation } from "react-router-dom";
  import dayjs from "dayjs";
  import axiosInstance from "../../Api/axios.instance.js";
  import { LoadingButton } from "@mui/lab";
  import { IconButton } from "@mui/material";
  import MoreVertIcon from "@mui/icons-material/MoreVert";
  import useSession from "../../CustomHooks/useSession.js";
  import MuiAlert from "@mui/material/Alert";
  import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
  import FileDownloadIcon from "@mui/icons-material/FileDownload";
  import { getClasses } from "../ClassSubjects/Classes.js";
  import { jsPDF } from "jspdf";
  import "jspdf-autotable";
  import * as XLSX from "xlsx";
  import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
  import { useNavigate } from "react-router-dom";
  import { AppContext } from "../../Context/Context.tsx";
  
  export default function SchoolObservationAdmin() {
  const context = useContext(AppContext);
    const [schoolObs, setSchoolObs] = useState([]);
    const [schoolList, setSchoolList] = useState([]);
    const { currentPage, accesLevel, access } = useSession();
    const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
    const { schools } = useSession();
    const [clusters, setClusters] = useState([]);
    const [open, setOpen] = useState(false);
    const [schoolName, setSchoolName] = useState("");
    const [sid, setSid] = useState(0);
    const [formData, setFormData] = useState({
      datefrom: dayjs().format("YYYY-MM-DD"),
      dateto: dayjs().format("YYYY-MM-DD"),
    });
    const navigate = useNavigate();
  
    const _onSubmit = async (e) => {
      e.preventDefault();
      const searchParams = new URLSearchParams();
      if (accesLevel === "ADMIN") {
        searchParams.append("datefrom", formData.datefrom);
        searchParams.append("dateto", formData.dateto);
      }
      try {
        const { data, status } = await axiosInstance.get(
          "/AdminDashboard/school-observation-schoolwise-monthwise",
          {
            params: searchParams,
          }
        );
        if (status === 200) {
          console.log("BBRRRRRRRRRRRRRRRRRRRR",data.data[0] )
            setSchoolObs(data.data[0]);
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    const _handleOnChange = (e) => {
      setFormData((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value,
        };
      });
    };
  
    const _handleDateFrom = (e) => {
      setFormData((prevState) => {
        return {
          ...prevState,
          datefrom: dayjs(e).format("YYYY-MM-DD"),
        };
      });
    };
  
    const _handleDateTo = (e) => {
      setFormData((prevState) => {
        return {
          ...prevState,
          dateto: dayjs(e).format("YYYY-MM-DD"),
        };
      });
    };
  
    const exportPDF = () => {
      const doc = new jsPDF({ orientation: "landscape" });
  
      doc.autoTable({
        html: "#my-table",
      });
  
      doc.save("Baithak-Report.pdf");
    };
  
    const exportXLSX = () => {
      handleExport().then((url) => {
        console.log(url);
        const downloadAnchorNode = document.createElement("a");
        downloadAnchorNode.setAttribute("href", url);
        downloadAnchorNode.setAttribute(
          "download",
          "school-observation-admin-report.xlsx"
        );
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
      });
    };
  
    const s2ab = (s) => {
      const buf = new ArrayBuffer(s.length);
  
      const view = new Uint8Array(buf);
  
      for (let i = 0; i !== s.length; ++i) {
        view[i] = s.charCodeAt(i);
      }
  
      return buf;
    };
  
    const workbook2Blob = (workbook) => {
      const wopts = {
        bookType: "xlsx",
        type: "binary",
      };
      const wbOut = XLSX.write(workbook, wopts);
  
      const blob = new Blob([s2ab(wbOut)], {
        type: "application/octet-stream",
      });
  
      return blob;
    };
  
    const handleExport = () => {
      let baithakLogo = [{}];
  
      let title = [{ A: "School Observation - Admin Report" }, {}];
  
      let table = [
        {
          A: "S.NO",
          B: "Region Name",
          C: "Name Of School",
          D: "January",
          E: "February",
          F: "March",
          G: "April",
          H: "May",
          I: "June",
          J: "July",
          K: "August",
          L: "September",
          M: "October",
          N: "November",
          O: "December",
        }
      ];
  
      schoolObs.length > 0 &&
      schoolObs.forEach((item, index) => {
          table.push({
            A: index + 1,
            B: item.RegionName,
            C: item.NameOfSchool,
            D: item.January,
            E: item.February,
            F: item.March,
            G: item.April,
            H: item.May,
            I: item.June,
            J: item.July,
            K: item.August,
            L: item.September,
            M: item.October,
            N: item.November,
            O: item.December,
          });
        });
  
      table = [{}].concat(table).concat([""]);
  
      const finalData = [...title, ...table];
  
      // create workbook
      const wb = XLSX.utils.book_new();
  
      // create worksheet
      const sheet = XLSX.utils.json_to_sheet(finalData, {
        skipHeader: true,
      });
  
      XLSX.utils.book_append_sheet(wb, sheet, "report-school-observations");
  
      const workbookBlob = workbook2Blob(wb);
  
      const headerIndexes = [];
      finalData.forEach((data, index) =>
        data["A"] === "S.No" ? headerIndexes.push(index) : null
      );
  
      const dataInfo = {
        titleCell: "A2",
        titleRange: "A1:O2",
        tbodyRange: `A3:O${finalData.length}`,
        theadRange:
          headerIndexes.length >= 1
            ? `A${headerIndexes[0] + 1}:Q${headerIndexes[0] + 1}`
            : null,
      };
      return addStyles(workbookBlob, dataInfo);
    };
  
    const addStyles = (workbookBlob, dataInfo) => {
      return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
        workbook.sheets().forEach((sheet) => {
          sheet.column("A").width(15);
          sheet.column("B").width(15);
          sheet.column("C").width(15);
          sheet.column("D").width(15);
          sheet.column("E").width(15);
          sheet.column("F").width(15);
          sheet.column("G").width(15);
          sheet.column("H").width(15);
          sheet.column("I").width(15);
          sheet.column("J").width(15);
          sheet.column("K").width(15);
          sheet.column("L").width(15);
          sheet.column("M").width(15);
          sheet.column("N").width(15);
          sheet.column("O").width(15);
          sheet.range(dataInfo.titleRange).merged(true).style({
            bold: true,
            horizontalAlignment: "center",
            verticalAlignment: "center",
          });
  
          sheet.range(dataInfo.titleRange).style({
            horizontalAlignment: "center",
            verticalAlignment: "center",
          });
  
          sheet.range(dataInfo.tbodyRange).style({
            horizontalAlignment: "left",
            verticalAlignment: "center",
          });
  
          sheet.range(dataInfo.theadRange).style({
            fill: "808080",
            bold: true,
            horizontalAlignment: "left",
            verticalAlignment: "center",
          });
        });
  
        return workbook
          .outputAsync()
          .then((workbookBlob) => URL.createObjectURL(workbookBlob));
      });
    };
  
    return (
      <Box flexGrow={1} sx={{ width: "100%" }}>
        <Stack
          sx={{ mb: 2, width: "100%" }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            sx={{
              width: "100%",
              mb: 2,
              display: "flex",
              justifyContent: "space-between",
            }}
            direction="row"
          >
            <Typography variant="h6" component="h1">
             School Observation School Wise And Month Wise
            </Typography>
  
            <LoadingButton
              type="submit"
              onClick={() => {
                navigate(-1);
              }}
              variant="contained"
              color="primary"
            >
              Back to Admin Dashbaord
            </LoadingButton>
          </Stack>
        </Stack>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <LoadingButton
            type="submit"
            onClick={() => {
              let quitSchoolData=[];
              schoolObs.forEach((item, index) => {
                quitSchoolData.push({
                  "S.No": index + 1,
                  "Region Name": item.RegionName,
                  "School Name": item.NameOfSchool,
                  "January": item.Jan,
                  "February": item.Feb,
                  "March": item.Mar,
                  "April": item.Apr,
                  "May": item.May,
                  "June": item.Jun,
                  "July": item.Jul,
                  "August": item.Aug,
                  "September": item.Sep,
                  "October": item.Oct,
                  "November": item.Nov,
                "December": item.Dec,
                });
              });
              context.dispatch({Payload:{dataForPivotTable:[...quitSchoolData]}, Type:'DATA FOR PIVOT TABLE'})
              navigate("/reports/react-pivot-table");
            }}
            fullWidth
            variant="contained"
            color="primary"
          >
            Visualizer{" "}
          </LoadingButton>
        </Grid>
          <Grid item xs={12} md={8}>
            <Box textAlign="right">
              <Button
                variant="outlined"
                color="primary"
                sx={{ mr: 1 }}
                onClick={exportPDF}
              >
                <PictureAsPdfIcon color="primary" />
              </Button>
              <Button onClick={exportXLSX} variant="outlined" color="primary">
                <FileDownloadIcon color="primary" />
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                inputFormat="DD-MM-YYYY"
                label="Date From"
                name="from"
                value={formData.datefrom}
                onChange={_handleDateFrom}
                renderInput={(params) => (
                  <TextField
                    sx={{ display: "flex", flex: 1 }}
                    size="small"
                    required
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                inputFormat="DD-MM-YYYY"
                label="Date To"
                name="to"
                value={formData.dateto}
                onChange={_handleDateTo}
                renderInput={(params) => (
                  <TextField
                    sx={{ display: "flex", flex: 1 }}
                    size="small"
                    required
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={4}>
            <LoadingButton
              //   loading={loading}
              //   disabled={loading}
              type="submit"
              onClick={_onSubmit}
              fullWidth
              variant="contained"
              color="primary"
            >
              Get School Observation Summary{" "}
            </LoadingButton>
          </Grid>
        </Grid>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Stack
          sx={{ mb: 2 }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <div style={{ width: "100%" }}>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                id="my-table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">S.No</TableCell>
                    <TableCell align="center">Region Name</TableCell>
                    <TableCell align="center">School Name</TableCell>
                    <TableCell align="center">January</TableCell>
                    <TableCell align="center">February</TableCell>
                    <TableCell align="center">March</TableCell>
                    <TableCell align="center">April</TableCell>
                    <TableCell align="center">May</TableCell>
                    <TableCell align="center">June</TableCell>
                    <TableCell align="center">July</TableCell>
                    <TableCell align="center">August</TableCell>
                    <TableCell align="center">September</TableCell>
                    <TableCell align="center">October</TableCell>
                    <TableCell align="center">November</TableCell>
                    <TableCell align="right">December</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {schoolObs.length > 0 &&
                    schoolObs.map((item, index) => {
                      return (
                        <TableRow
                          key={String(index)}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="center">
                            {item.RegionName}
                          </TableCell>
                          <TableCell align="center">
                            {item.NameOfSchool}
                          </TableCell>
                          <TableCell align="center">{item.Jan}</TableCell>
                          <TableCell align="center">{item.Feb}</TableCell>
                          <TableCell align="center">{item.Mar}</TableCell>
                          <TableCell align="center">{item.Apr}</TableCell>
                          <TableCell align="center">{item.May}</TableCell>
                          <TableCell align="center">{item.Jun}</TableCell>
                          <TableCell align="center">{item.Jul}</TableCell>
                          <TableCell align="center">{item.Aug}</TableCell>
                          <TableCell align="center">{item.Sep}</TableCell>
                          <TableCell align="center">{item.Oct}</TableCell>
                          <TableCell align="center">{item.Nov}</TableCell>
                          <TableCell align="right">{item.Dec}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Stack>
      </Box>
    );
  }
  